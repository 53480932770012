import './styles/App.css';
import { details, experience, portfolio, accomplishments } from './data/content';
import { ReactComponent as GithubLogo } from './svgs/github.svg';
import { ReactComponent as LinkedInLogo } from './svgs/linkedin.svg';
import { ReactComponent as ExternalLinkIcon } from './svgs/external-link.svg';
import React from 'react';
function App() {
  return (
    <div className="bg-slate-900 leading-relaxed text-slate-400 antialiased selection:bg-teal-300 selection:text-teal-900">

        <div id="__next">
          <div class="__variable_20b187 group/spotlight relative">
            <div class="pointer-events-none fixed inset-0 z-30 transition duration-300 lg:absolute" style={{
              background: 'radial-gradient(600px circle at 0px 0px, rgba(29, 78, 216, 0.15), transparent 80%)'
            }}
            />
            <div class="mx-auto min-h-screen max-w-screen-xl px-6 py-12 font-sans md:px-12 md:py-20 lg:px-24 lg:py-0"><a
              href="#content"
              class="absolute left-0 top-0 block -translate-x-full rounded bg-gradient-to-br from-teal-400 via-blue-500 to-purple-600 px-4 py-3 text-sm font-bold uppercase tracking-widest text-white focus-visible:translate-x-0">Skip
              to Content</a>
              <div class="lg:flex lg:justify-between lg:gap-4">
                <header
                  class="lg:sticky lg:top-0 lg:flex lg:max-h-screen lg:w-1/2 lg:flex-col lg:justify-between lg:py-24">
                  <div>
                    <h1 class="text-4xl font-bold tracking-tight text-slate-200 sm:text-5xl"><a
                      href="/">{details.name}</a></h1>
                    <h2 class="mt-3 text-lg font-medium tracking-tight text-slate-200 sm:text-xl">{details.title}</h2>
                    <p class="mt-4 max-w-xs leading-normal">{details.desc}</p>
                    <nav class="nav hidden lg:block" aria-label="In-page jump links">
                      <ul class="mt-16 w-max">
                        <li><a class="group flex items-center py-3 active" href="#about"><span
                          class="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none"></span><span
                            class="nav-text text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200">About</span></a>
                        </li>
                        <li><a class="group flex items-center py-3 " href="#experience"><span
                          class="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none"></span><span
                            class="nav-text text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200">Experience</span></a>
                        </li>
                        <li><a class="group flex items-center py-3 " href="#projects"><span
                          class="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none"></span><span
                            class="nav-text text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200">Projects</span></a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <ul class="ml-1 mt-8 flex items-center" aria-label="Social media">
                    <li class="mr-5 text-xs shrink-0"><a class="block hover:text-slate-200"
                      href={details.gitHub} target="_blank" rel="noreferrer noopener"
                      aria-label="GitHub (opens in a new tab)" title="GitHub"><span
                        class="sr-only">GitHub</span><GithubLogo /></a></li>
                    <li class="mr-5 text-xs shrink-0"><a class="block hover:text-slate-200"
                      href={details.linkedIn} target="_blank"
                      rel="noreferrer noopener" aria-label="LinkedIn (opens in a new tab)"
                      title="LinkedIn"><span class="sr-only">LinkedIn</span><LinkedInLogo /></a></li>
                  </ul>
                </header>
                <main id="content" class="pt-24 lg:w-1/2 lg:py-24">
                  <section id="about" class="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
                    aria-label="About me">
                    <div
                      class="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
                      <h2 class="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">About
                      </h2>
                    </div>
                    <div>
                      {details.about.map((data, i) => {
                        return (
                          <p class="mb-4">{data}</p>
                        )
                      })}
                    </div>
                  </section>
                  <section id="experience" class="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
                    aria-label="Work experience">
                    <div
                      class="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
                      <h2 class="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">
                        Experience</h2>
                    </div>
                    <div>
                      <ol class="group/list">
                        {experience.map((data, i) => {
                          return (

                            <li class="mb-12">
                              <div
                                class="group relative grid pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                                <div
                                  class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg">
                                </div>
                                <header
                                  class="z-10 mb-2 mt-1 text-xs font-semibold uppercase tracking-wide text-slate-500 sm:col-span-2"
                                  aria-label={data.year}>{data.year}</header>
                                <div class="z-10 sm:col-span-6">
                                  <h3 class="font-medium leading-snug text-slate-200">
                                    <div><a class="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300  group/link text-base"
                                      href={data.link} target="_blank"
                                      rel="noreferrer noopener"
                                      aria-label={i}><span
                                        class="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block"></span><span>{data.title} · <span
                                          class="inline-block">{data.company}<ExternalLinkIcon /></span></span></a></div>
                                  </h3>
                                  <p class="mt-2 text-sm leading-normal">{data.desc}</p>
                                  <ul class="mt-2 flex flex-wrap" aria-label="Technologies used">
                                    {data.tech.map((tech, i) => {
                                      return (
                                        <li class="mr-1.5 mt-2">
                                          <div
                                            class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">
                                            {tech}</div>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                      </ol>
                      <div class="mt-12"><a
                        class="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300 font-semibold text-slate-200 group/link text-base"
                        href="/cv" target="_blank" rel="noreferrer noopener"
                        aria-label="View Full Résumé (opens in a new tab)"><span>View Full <span
                          class="inline-block">Résumé
                          <ExternalLinkIcon /></span></span></a></div>
                    </div>
                  </section>
                  <section id="projects" class="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
                    aria-label="Selected projects">
                    <div
                      class="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
                      <h2 class="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">
                        Projects</h2>
                    </div>
                    <div>
                      <ul class="group/list">
                        {portfolio.map((data, i) => {
                          return (
                            <li class="mb-12">
                              <div
                                class="group relative grid gap-4 pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                                <div
                                  class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg">
                                </div>
                                <div class="z-10 sm:order-2 sm:col-span-6">
                                  <h3><a class="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300  group/link text-base"
                                    href={data.link}
                                    target="_blank" rel="noreferrer noopener"
                                    aria-label={i}><span
                                      class="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block"></span><span>
                                      {data.title} <span
                                        class="inline-block"><ExternalLinkIcon /></span></span></a></h3>
                                  <p class="mt-2 text-sm leading-normal">{data.desctiption}</p>
                                </div><img alt="Insult Master"
                                  loading="lazy" width="200" height="48" decoding="async" data-nimg="1"
                                  class="rounded border-2 border-slate-200/10 transition group-hover:border-slate-200/30 sm:order-1 sm:col-span-2 sm:translate-y-1"
                                  style={{ color: 'transparent' }}
                                  srcSet={data.img}
                                  src={data.img} />
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                      <div class="mt-12"><a
                        class="inline-flex items-center font-medium leading-tight text-slate-200 font-semibold text-slate-200 group"
                        aria-label="View Full Project Archive" href="archive.html"><span><span
                          class="border-b border-transparent pb-px transition group-hover:border-teal-300 motion-reduce:transition-none">View
                          Full Project </span><span class="whitespace-nowrap"><span
                            class="border-b border-transparent pb-px transition group-hover:border-teal-300 motion-reduce:transition-none">Archive</span>
                            <ExternalLinkIcon /></span></span></a></div>
                    </div>
                  </section>
                  <section id="accomplishment" class="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
                    aria-label="accomplishment">
                    <div
                      class="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
                      <h2 class="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">
                        Accomplishment</h2>
                    </div>
                    <div>
                      <ul class="group/list">
                        {accomplishments.map((data, i) => {
                          return (
                            <li class="mb-12">
                              <div
                                class="group relative grid grid-cols-8 gap-4 transition-all sm:items-center sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                                <div
                                  class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg">
                                </div><img alt="Telescope" loading="lazy" width="200" height="48"
                                  decoding="async" data-nimg="1"
                                  class="z-10 col-span-2 rounded border-2 border-slate-200/10 transition group-hover:border-slate-200/30 sm:col-span-2"
                                  style={{ color: 'transparent' }}
                                  srcSet={data.image}
                                  src={data.image} />
                                <div class="z-10 col-span-6">
                                  <p class="-mt-1 text-sm font-semibold leading-6">{data.year}</p>
                                  <h3 class="-mt-1"><a
                                    class="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300  group/link text-base"
                                    href={data.link}
                                    target="_blank" rel="noreferrer noopener"
                                    aria-label={data.title}><span
                                      class="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block"></span><span>{data.title}<span
                                        class="inline-block"><ExternalLinkIcon /></span></span></a></h3>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </section>
                  <footer class="max-w-md pb-16 text-sm text-slate-500 sm:pb-0">
                    <p>Coded in Visual Studio Code by me. Built with React and Bun, deployed with Vercel.</p>
                  </footer>
                </main>
              </div>
            </div>
          </div>
        </div>

      </div>

  );
}

export default App;
