import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ResetComponent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Reset the visit count in local storage
    localStorage.setItem('visitCount', 0);
    localStorage.setItem('storedMonth', new Date().getMonth());

    // Redirect to the home page after resetting
    navigate('/');
  }, [navigate]);

  return (
    <div>
      <h1>Resetting visit count...</h1>
    </div>
  );
};

export default ResetComponent;