const details = {
    name: "Bradley Barker",
    title: "Senior Developer",
    desc: "Skilled developer with versatile expertise",
    linkedIn: "https://www.linkedin.com/in/barkerbg001/",
    gitHub: "https://github.com/barkerbg001",
    about: [
        "I am a dedicated Senior Developer with a solid background in full-stack development, particularly with Xamarin, ASP.NET, and WCF Services. Over the past 4 years at Thoughtware, I’ve gained experience in Docker, and Azure DevOps Services. I focus on delivering high-quality software solutions and enjoy collaborating with cross-functional teams to drive projects from start to finish. I am also continuously enhancing my skills in .NET, Python, and React through personal projects. I hold a Bachelor’s degree in Information Technology from Pearson Institute of Higher Education, where I maintained strong academic performance and received several awards."
    ],
};
const experience = [
    {
        title: "Senior Developer",
        company: "Thoughtware",
        link: "https://www.thoughtware.co.za",
        year: "Mar 2024 — Present",
        desc: "Leading the development of the company's procurement/stock management application, focusing on Xamarin and ASP.NET Web API integration. Currently spearheading the migration of the application from Xamarin to .NET MAUI. Responsible for creating detailed project plans for team members, ensuring efficient task allocation and progress tracking. Contributed to projects involving Docker, Kubernetes, and Azure DevOps Services.",
        tech: ["Docker", "Git", "Xamarin", "Microsoft SQL Server", "WCF", "ASP.NET", "Figma", "Angular", "Azure DevOps", "ASP.NET Web API", "Project Management"]
    },
    {
        title: "Semi Senior Developer",
        company: "Thoughtware",
        link: "https://www.thoughtware.co.za",
        year: "Mar 2022 — Mar 2024",
        desc: "Halfway through this role, the team lead resigned, and all other team members left, leaving me as the sole developer. I continued to develop and enhance the procurement/stock management application, built Docker images for the .NET API and Angular portal projects, and helped implement Docker-based solutions and streamline CI/CD processes using Azure DevOps. I also onboarded and trained three new developers.",
        tech: ["Docker", "Git", "Xamarin", "Microsoft SQL Server", "WCF", "ASP.NET", "Figma", "Angular", "Azure DevOps", "ASP.NET Web API", "Project Management"]
    },
    {
        title: "Experienced Developer",
        company: "Thoughtware",
        link: "https://www.thoughtware.co.za",
        year: "Mar 2021 — Mar 2022",
        desc: "Started the creation of a separate API and led a comprehensive redesign of the UI for the procurement/stock management application based on designs provided by our designers.",
        tech: ["Git", "Xamarin", "Microsoft SQL Server", "WCF", "ASP.NET", "Figma"]
    },
    {
        title: "Application Developer Consultant",
        company: "Thoughtware",
        link: "https://www.thoughtware.co.za",
        year: "Jan 2020 — Mar 2021",
        desc: "Worked in a team to maintain, update, and improve the procurement/stock management application written in Xamarin. Tasked with removing Syncfusion from the app and finding open-source alternatives for each feature being used. Ensured high-quality deliverables and client satisfaction.",
        tech: ["Git", "Xamarin", "Microsoft SQL Server", "WCF", "ASP.NET", "Figma"]
    }];
const portfolio = [
    {
        img: "https://dashcube.barkerbg001.com/logo.svg",
        title: "DashCube",
        link: "https://dashcube.barkerbg001.com",
        desctiption: "DashCube is an arcade-style game developed in React, where players control a yellow cube navigating a city skyline filled with obstacles. It features a responsive and challenging gameplay environment with a unique scoring system, life count, and high score tracking."
    },
    {
        img: "DiaryVerse.webp",
        title: "Diary Verse",
        link: "https://play.google.com/store/apps/details?id=com.barkerbg001.DiaryVerse",
        desctiption: "Diary Verse is a versatile and user-friendly diary application designed to simplify the task of keeping and managing personal diaries. With its intuitive interface, users can effortlessly create, update, and review their entries. The app offers multi-diary support, allowing users to maintain separate diaries for different themes or aspects of their lives, such as work, personal life, travel, and more. Stay organized, capture your daily experiences, and track your personal growth with Diary Verse."
    },
    {
        img: "DadJokesGalore.webp",
        title: "Dad Jokes Galore",
        link: "https://play.google.com/store/apps/details?id=com.barkerbg001.dad_jokes_galore",
        desctiption: "Dad Jokes Galore is your go-to destination for all things dad jokes. With our app, you'll have endless entertainment right at your fingertips. Our user-friendly interface makes browsing and discovering new jokes a breeze. The warm and inviting color scheme creates a cozy and comfortable atmosphere, while the legible font ensures that the jokes are easy to read for all. Whether you're looking for a quick chuckle or a roll-on-the-floor-laugh, we've got you covered. Download Dad Jokes Galore today and get ready to be entertained!"
    },
    {
        img: "InsultMaster.webp",
        title: "Insult Master",
        link: "https://play.google.com/store/apps/details?id=com.barkerbg001.insult_master",
        desctiption: "Insult Master is the ultimate tool for unleashing your inner villain. With a vast collection of cutting-edge insults, this app will have you dispensing scathing put-downs in no time."
    }
];
const accomplishments = [
    {
        year: "2024",
        title: "CS50 Artificial Intelligence",
        image: "ai.jpg",
        link: "https://certificates.cs50.io/297f999b-dc7e-409a-8972-0236a509347b.pdf?size=letter"
    },
    {
        year: "2022",
        title: "Crash Course on Python",
        image: "python.jpg",
        link: "https://www.coursera.org/account/accomplishments/certificate/CC5ZRABFRDZC"
    }];

export {
    details,
    experience,
    portfolio,
    accomplishments
};
