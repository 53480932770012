import React from 'react';
import './styles/index.css';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import PDF from "./PDFPage";
import ResetComponent from './reset';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"

export default function Index() {
    return (
      <BrowserRouter>
        <Routes>
          <Route index element={<App />} />
          <Route path="cv" element={<PDF />} />
          <Route path='reset' element={<ResetComponent/>}/>
          <Route path="*" element={<App />} />
        </Routes>
        <SpeedInsights/>
        <Analytics/>
      </BrowserRouter>
    );
  }
  
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(<Index />);

reportWebVitals(sendToVercelAnalytics);
