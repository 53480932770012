import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

// Create Document Component
const MyDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Still Creating It.</Text>
      </View>
    </Page>
  </Document>
);

const PDFPage = () => {
  return (
    <PDFViewer style={{ width: '100%', height: '100%' }}>
      <MyDocument />
    </PDFViewer>
  );
};

export default PDFPage;
